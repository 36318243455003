import 'lazysizes';
import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'

window.Alpine = Alpine

Alpine.plugin(intersect)
Alpine.plugin(collapse)
Alpine.plugin(focus)
Alpine.start()


/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR")
  });
}

// THIS CONTROLS THE RECAPTCHA FOR EMAIL FOOTER SIGNUP

document.addEventListener("DOMContentLoaded", function () {
  const form = document.querySelector("#footer-email");

  if (!form) return; // Exit if form doesn't exist

  form.addEventListener("submit", function (event) {
    event.preventDefault(); // Stop immediate submission

    grecaptcha.ready(function () {
      grecaptcha.execute("6LeiJtsqAAAAALdBu_OAd3JZ4hyfFMuUliYilrEN", { action: "submit" }).then(function (token) {

        if (!token) {
          alert("reCAPTCHA failed. Try again.");
          return;
        }

        // Set token in the hidden input field
        document.getElementById("g-recaptcha-response").value = token;

        // Validate reCAPTCHA before submitting the form
        fetch('/actions/formrecaptcha/form/validate-recaptcha', {
          method: 'POST',
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
          body: new URLSearchParams({ 'g-recaptcha-response': token })
        })
        .then(response => response.json())
        .then(data => {
          console.log("reCAPTCHA Response:", data);

          if (data.success) {
            console.log("✅ reCAPTCHA Passed! Submitting form...");

            // ✅ Ensure the ACTION_SUBMIT_SURVEY_RESPONSE value is submitted
            let hiddenSubmit = document.createElement("input");
            hiddenSubmit.type = "hidden";
            hiddenSubmit.name = "ACTION_SUBMIT_SURVEY_RESPONSE";
            hiddenSubmit.value = "1"; // Any value to ensure it’s included

            form.appendChild(hiddenSubmit);

            // ✅ Now, manually submit the form
            form.submit();
          } else {
            alert("❌ reCAPTCHA Failed. Please try again.");
          }
        })
        .catch(error => {
          console.error("Error:", error);
          alert("An error occurred while verifying reCAPTCHA.");
        });
      });
    });
  });
});